// // t server
// export const server = "https://Swaprdn.zinfog.com/";
//export const server = "https://swaecomindiamain.swa.co/";
export const server = "https://swaecommain.swa.co/";
//home page api
export const home = server + "ecom/home/";
//add wishlist
export const addWishList = server + "ecom/wishlist/";
//product details
export const productDet = server + "ecom/products/";

export const productCategoryByMetal = server + "ecom/products/?metal_type=";
export const filterProductsById = server + "ecom/products/?category_ids=";

//register
export const register = server + "ecom/register/";
//sent otp
export const sentOtp = server + "api/send-otp/";
//verify otp
export const verifyOTP = server + "api/verify-otp/";
//login
export const Login = server + "ecom/ecomm-login/";
//wishlist
export const wishlist = server + "ecom/wishlist/";
//product list
export const productList = server + "ecom/products";
//filter
export const filter = server + "ecom/components/";
//add to cart
export const cart = server + "ecom/cartitems/";
//promo code
export const promoCode = server + "ecom/applypromo/";
//ADDRESS
export const address = server + "ecom/addresses/all/";
//add addresss
export const addAdress = server + "ecom/addresses/";
//place order
export const checkout = server + "ecom/checkout/";
//create review
export const review = server + "ecom/products/review/";
// show reviews
export const showreview = server + "ecom/products/";
//my order
export const myOrder = server + "ecom/orders/";
//buy now
export const buyNow = server + "ecom/buynow/";
//order pay success
export const paySuces = server + "ecom/payment-res/";
//pincode token
//search  suggetion
export const suggestion = server + "ecom/recommendations/?keyword=";
//add recent
export const addRecent = server + "ecom/recent/";
//update password
export const updatePas = server + "api/update/";
//pincode check
export const pincodeCheck = server + "ecom/pincode_check/?pincode=";

// getContrey Details
export const getCountryFlags = server + "ecom/country-details/";

//track
export const track = server + "ecom/product-tracking/";

//metalType filter
export const metalType = server + "ecom/products/?metal_type=3";

//metalCategory
export const metalCategory = server + "ecom/metal_types/";

//filterCatgories
export const categoryWise = server + "ecom/categories";

// occationCategories
export const occationalProdByid = server + "ecom/products/?occasion_tag_ids=";
export const occationalProducts = server + "ecom/tags";

export const pinCodeToken =
  "https://bvcmars.com/RestService/OrderUploadService.svc/GenerateAuthenticationToken";
//pincode
export const pinCode =
  "https://bvcmars.com/bvc_staging/RestService/PincodeMasterService.svc/GetPincodeData";

//track order
export const trackOrder =
  "https://bvcmars.com/RestService/TrackingService.svc/GetDocketTrackingDetails";

export const CancelOrder = server + "ecom/cancel-order";
export const defaultAddress = server + "ecom/address/";
export const addBankAccount = server + "ecom/bank/";
export const BankLists = server + "ecom/bank/";
export const withdrawamount = server + "ecom/withdraw_amount/";
export const withdrawamountDetails = server + "ecom/withdraw_amount/";
export const getWalletAmounts = server + "ecom/checkout/";
export const singleReview = server + "ecom/review/";
export const headeroffer = server + "ecom/header-details/";
export const videoCallPost = server + "ecom/add_video_call/";
export const updateuser = server + "ecom/update_user/";
// try at home
export const tryathome = server + "ecom/trialcartitems/";
// try cart
export const tryatcart = server + "ecom/trialcartitems/";
// try cart image delete
export const tryatcartdelete = server + "ecom/trialcartitems";
// book appointment
export const bookappointment = server + "ecom/trialcartitems/";
// check delivery date

export const checkdeliveryDate = server + "ecom/check-delivery-date/";
